import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Link, Stack, TextField } from '@mui/material';
import axios from 'axios';


export default function LogoCollection({blok,setBlok,iPoz,setPolitika,dannie,setDannie,iiPoz,iiiPoz,ivPoz,vPoz,viPoz}) {
  
  const [fio, setFIO] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [err, setErr] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const fioHandleChange = (event) => {
    setFIO(event.target.value);
  };
  const phoneHandleChange = (event) => {
    setPhone(event.target.value);
  };
  const emailHandleChange = (event) => {
    setEmail(event.target.value);
  };
  const forms1=()=>{
    let err=''; let errcod=0;
    if(fio==null || fio==''){err=err+'Введите ФИО! ';errcod=1;}
    if(phone==null || phone==''){err=err+`Введите телефон! `;errcod=1;}
    if(email==null || email==''){err=err+`Введите email! `;errcod=1;}
    setErr(err)
    if (errcod!=0){setOpen(true);}
    else{
    
    axios.get('https://api.marivek.ru/mails_otpr/mailscalc.php?dann='+dannie+'. '+fio+', '+phone+', '+email+'&cods=123&poz1='+iPoz+'&poz2='+iiPoz+'&poz3='+iiiPoz+'&poz4='+ivPoz+'&poz5='+vPoz+'&poz6='+viPoz)
    .then(function (response) {
      console.log(response.data.result);
    })
    .catch(function (error) {
      console.log(error);
    })
    .finally(function () {
      scrollToSection('hero')
      setDannie(dannie+'. '+fio+', '+phone+', '+email)
      setBlok(2)
    });

    
    }
  }
  const handleClose = () => {
    setOpen(false);
  };
  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };

  const PersDann = () => {
    setPolitika(true);
  };

  return (
    <Box id="logoCollection" >
    {
      blok==1 && <>
      <Typography component="h2" variant="h4" color="text.primary" align="center">
        Ваше число 1ой позиции - {iPoz}
      </Typography>
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        color="text.secondary"
      >
        Для получения значения своей задачи души <b style={{color:"#c73080"}}>БЕСПЛАТНО</b> (1ой позиции) заполните следующие данные
      </Typography>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' },  }}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <TextField id="standard-basic" label="Введите ваше ФИО" variant="standard" value={fio} onChange={fioHandleChange}/>
          </FormControl>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <TextField id="standard-basic" label="Введите ваш телефон" variant="standard" value={phone} onChange={phoneHandleChange}/>
          </FormControl>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <TextField id="standard-basic" label="Введите ваш email" variant="standard" value={email} onChange={emailHandleChange}/>
          </FormControl>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <Button variant="contained" onClick={()=>forms1()} style={{backgroundColor: "#c73080"}}>
                  Получить
              </Button>
          </FormControl>
          <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }}>
            Нажимая кнопку получить вы соглашаетесь с уловиями &nbsp; 
            <Link color="primary" onClick={PersDann}>
              обработки персональных данных
            </Link>
            .
          </Typography>
        </Stack>
      </Container>
      </>
    }
    <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Внимание!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {err}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus style={{backgroundColor: "#c73080", color:'#fff'}}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </Box>
  );
}
import * as React from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import Hero from './components/Hero';
import LogoCollection from './components/LogoCollection';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import { Button } from '@mui/material';
import Footer from './components/Footer';
import Politika from './components/Politika';
import Cart from './components/Cart';
import { Resend } from 'resend';
import "./App.css";


function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {

  

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        position: 'fixed',
        bottom: 24
      }}
    >
        <div style={{background:"#E6E3E2",padding:"20px",fontSize:'10px'}}>
          Мы используем cookie-файлы, чтобы получить статистику, которая помогает нам улучшить 
          сервис для Вас с целью персонализации сервисов и предложений. Вы можете прочитать 
          подробнее о cookie-файлах или изменить настройки браузера. Продолжая пользоваться 
          сайтом без изменения настроек, вы даёте согласие на использование ваших cookie-файлов.
          <div style={{textAlign:"right"}}><Button>Ok</Button></div>
        </div>
    </Box>
  );
}



ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.shape({
    valueOf: PropTypes.func.isRequired,
  }).isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

let theme = createTheme({
  palette: {
    primary: {
      main: '#c73080',
    },
    secondary: {
      main: '#edf2ff',
    },
  },
});

function App() {
  const [mode, setMode] = React.useState('light');
  const defaultTheme = createTheme({ palette: { mode } });
  
  const [blok, setBlok] = React.useState(0);
  const [iPoz, setIPoz] = React.useState(0);
  const [iiPoz, setIIPoz] = React.useState(0);
  const [iiiPoz, setIIIPoz] = React.useState(0);
  const [ivPoz, setIVPoz] = React.useState(0);
  const [vPoz, setVPoz] = React.useState(0);
  const [viPoz, setVIPoz] = React.useState(0);

  const [dannie, setDannie] = React.useState('');

  const [politika, setPolitika] = React.useState(false);

  const [cookie, setCookie] = React.useState(localStorage.getItem('cookie'));

  const btncooki = () => {
    localStorage.setItem('cookie', 1);
    setCookie(1)
  }
  
  const mailsend = () => {
    /*console.log('1231231231231')
    console.log(dannie)*/
    
    window.location.replace('https://vk.com/marivek')
  }

  return (
    <ThemeProvider theme={theme} >
      <CssBaseline />
      <Hero  blok={blok} setBlok={setBlok} setIPoz={setIPoz} setIIPoz={setIIPoz} 
        setIIIPoz={setIIIPoz} setIVPoz={setIVPoz} setVPoz={setVPoz} setVIPoz={setVIPoz}  
        setPolitika={setPolitika} setDannie={setDannie}/>
      <Box sx={{ bgcolor: 'background.default' }}>
        <LogoCollection blok={blok} setBlok={setBlok} iPoz={iPoz} setPolitika={setPolitika} dannie={dannie} setDannie={setDannie} 
        iiPoz={iiPoz} iiiPoz={iiiPoz} ivPoz={ivPoz} vPoz={vPoz} viPoz={viPoz}/>
        <Features blok={blok} iPoz={iPoz} mailsend={mailsend}/>
        <Cart blok={blok} iPoz={iPoz} iiPoz={iiPoz} iiiPoz={iiiPoz} ivPoz={ivPoz} vPoz={vPoz} viPoz={viPoz}/>
        <Footer />
      </Box>
      <Politika open={politika} setPolitika={setPolitika}/>
      {cookie==null && <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        position: 'fixed',
        bottom: 24
      }}
    >
        <div style={{background:"#E6E3E2",padding:"20px",fontSize:'10px'}}>
          Мы используем cookie-файлы, чтобы получить статистику, которая помогает нам улучшить 
          сервис для Вас с целью персонализации сервисов и предложений. Вы можете прочитать 
          подробнее о cookie-файлах или изменить настройки браузера. Продолжая пользоваться 
          сайтом без изменения настроек, вы даёте согласие на использование ваших cookie-файлов.
          <div style={{textAlign:"right"}}><Button onClick={()=>btncooki()} style={{backgroundColor: "#c73080", color:'#fff'}}>Ok</Button></div>
        </div>
    </Box>}
      
    </ThemeProvider>
  );
}

export default App;

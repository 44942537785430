import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Input, InputLabel, MenuItem, Select, alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';



export default function Hero({blok,setBlok,setIPoz,setIIPoz,setIIIPoz,setIVPoz,setVPoz,setVIPoz,setPolitika,setDannie}) {

    const [dr, setDr] = React.useState('');
    const [mes, setMes] = React.useState('');
    const [god, setGod] = React.useState('');
    const [err, setErr] = React.useState('');
    const [open, setOpen] = React.useState(false);

    const drHandleChange = (event) => {
        setDr(event.target.value);
    };
    const mesHandleChange = (event) => {
        setMes(event.target.value);
    };
    const godHandleChange = (event) => {
      setGod(event.target.value);
    };
    const forms1=()=>{
      let err=''; let errcod=0;
      if(dr==null || dr==''){err=err+'Выберите ДАТУ рождения! ';errcod=1;}
      if(mes==null || mes==''){err=err+`Выберите МЕСЯЦ рождения! `;errcod=1;}
      
      if (/^\d+$/.test(god)) {
        if(god.length<4){err=err+`В годе рождения меньше 4х цифр! `;errcod=1;}
        else if(god.length>4){err=err+`В годе рождения больше 4х цифр! `;errcod=1;}
      }else{
        {err=err+`В годе рождения содержатся не только цифр! `;errcod=1;}
      }
      
      setErr(err)
      if (errcod!=0){setOpen(true);}
      else{
      scrollToSection('hero')
      let IPoz=0 
      let IIIPoz=0
      let IVPoz=0
      let VPoz=0
      let VIPoz=0
      if(dr<23){IPoz=dr}else{IPoz=dr+(-22)}
      setIPoz(Number(IPoz))
      setIIPoz(Number(mes))
      let IIIPozz=Number(god.substring(0, 1))+Number(god.substring(1, 2))+Number(god.substring(2, 3))+Number(god.substring(3, 4))
      if(IIIPozz<23){IIIPoz=IIIPozz}else{IIIPoz=IIIPozz+(-22)}
      setIIIPoz(Number(IIIPoz))
      let IVPozz=Number(IPoz)+Number(mes)
      if(IVPozz<23){IVPoz=IVPozz}else{IVPoz=IVPozz+(-22)}
      setIVPoz(Number(IVPoz))
      let VPozz=Number(mes)+Number(IIIPoz)
      if(VPozz<23){VPoz=VPozz}else{VPoz=VPozz+(-22)}
      setVPoz(Number(VPoz))
      let VIPozz=Number(IVPoz)+Number(VPoz)
      if(VIPozz<23){VIPoz=VIPozz}else{VIPoz=VIPozz+(-22)}
      setVIPoz(Number(VIPoz))
      setDannie(dr+' '+mes+' '+god)
      setBlok(1)
      
      }
    }
    const handleClose = () => {
      setOpen(false);
    };

    const scrollToSection = (sectionId) => {
      const sectionElement = document.getElementById(sectionId);
      const offset = 128;
      if (sectionElement) {
        const targetScroll = sectionElement.offsetTop - offset;
        sectionElement.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo({
          top: targetScroll,
          behavior: 'smooth',
        });
        setOpen(false);
      }
    };

    const PersDann = () => {
      setPolitika(true);
    };
    
/*CEE5FD
e9a5c9
c73080*/
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #e9a5c9, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 6, sm: 10 },
          pb: { xs: 4, sm: 8 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            Система самопознания на базе архетипов&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
            </Typography>
          </Typography>
          {
            blok==0 && <>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
          >
            Рассчитайте позиции основного портрета по дате рождения. 
          </Typography>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">Выберите вашу дату рождения</InputLabel>
                <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={dr}
                onChange={drHandleChange}
                label="Выберите вашу дату рождения"
                >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
                <MenuItem value={12}>12</MenuItem>
                <MenuItem value={13}>13</MenuItem>
                <MenuItem value={14}>14</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={16}>16</MenuItem>
                <MenuItem value={17}>17</MenuItem>
                <MenuItem value={18}>18</MenuItem>
                <MenuItem value={19}>19</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={21}>21</MenuItem>
                <MenuItem value={22}>22</MenuItem>
                <MenuItem value={23}>23</MenuItem>
                <MenuItem value={24}>24</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={26}>26</MenuItem>
                <MenuItem value={27}>27</MenuItem>
                <MenuItem value={28}>28</MenuItem>
                <MenuItem value={29}>29</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={31}>31</MenuItem>
                </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-standard-label">Выберите ваш месяц рождения</InputLabel>
                <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={mes}
                onChange={mesHandleChange}
                label="Выберите вашу дату рождения"
                >
                <MenuItem value={1}>Январь</MenuItem>
                <MenuItem value={2}>Февраль</MenuItem>
                <MenuItem value={3}>Март</MenuItem>
                <MenuItem value={4}>Апрель</MenuItem>
                <MenuItem value={5}>Май</MenuItem>
                <MenuItem value={6}>Июнь</MenuItem>
                <MenuItem value={7}>Июль</MenuItem>
                <MenuItem value={8}>Август</MenuItem>
                <MenuItem value={9}>Сентябрь</MenuItem>
                <MenuItem value={10}>Октябрь</MenuItem>
                <MenuItem value={11}>Ноябрь</MenuItem>
                <MenuItem value={12}>Декабрь</MenuItem>
                </Select>
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <TextField id="standard-basic" label="Введите ваш год рождения в формате 1990" value={god} variant="standard" onChange={godHandleChange}/>
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <Button variant="contained" onClick={()=>forms1()} style={{backgroundColor: "#c73080"}}>
                    Расчитать
                </Button>
            </FormControl>
          <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }}>
            Нажимая кнопку расчитать вы соглашаетесь с уловиями &nbsp; 
            <Link color="primary" onClick={PersDann}>
              обработки персональных данных
            </Link>
            .
          </Typography>
          </>
          }
          
        </Stack>
        
      </Container>

      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Внимание!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {err}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus style={{backgroundColor: "#c73080", color:'#fff'}}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>

    </Box>
  );
}
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/system";
import {
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Link,
  Stack,
  TextField,
} from "@mui/material";
import { alpha } from "@mui/material";
import dat from "../json/i_poz.json";
import { Diversity1Rounded } from "@mui/icons-material";

export default function Car({ pos, cart }) {
  let screenWidth = window.screen.width
  //console.log(screenWidth)
  let logoStyle=''
  if (screenWidth<=610){
     logoStyle = {
      width: "auto",
      height: "200px",
    };
  }else if (screenWidth>610 && screenWidth<=810){
    logoStyle = {
     width: "auto",
     height: "350px",
   };
 }else if (screenWidth>810 && screenWidth<=1010){
  logoStyle = {
   width: "auto",
   height: "450px",
 };}else{logoStyle = {
    width: "auto",
    height: "550px",
  };}
  
  return (
    <>
      {dat.razdel.map((razd, index) => {
        if (razd.id == cart) {
          let txt = pos + " - " + razd.name;
          return (
            <div style={{display:"inline-block", textAlign:"center",paddingRight:"10px"}}>
              <div>{txt}</div>
              <img
                src={process.env.PUBLIC_URL + "" + razd.img}
                alt={`Logo ${index + 1}`}
                style={logoStyle}
              />
            </div>
          );
        }
      })}
    </>
  );
}

/*<Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1,
              }}
              style={{textAlign:"center"}}
            >
              <Box
                sx={{
                  justifyContent: 'space-between',
                  pr: 2,
                }}
              >
                <CardHeader
                  title={txt}
                />
                <img
                  src={process.env.PUBLIC_URL+''+razd.img}
                  alt={`Logo ${index + 1}`}
                  style={logoStyle}
                />
              </Box>
            </Card>
          </Grid>*/

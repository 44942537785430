import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import imgs from '../img/pozition_i/1.jpg'
import dat from '../json/i_poz.json'

export default function Features({blok,iPoz,mailsend}) {
  
  
  return (
    <Container id="features" >
    {
      blok==2 && (
        dat.razdel.map((razd,index) => {
          if (razd.id==iPoz){
            return(
            <Grid container spacing={6} key={index}>
              <Grid item xs={12} md={6}>
                <Box
                  variant="outlined"
                  sx={{
                    display: { xs: 'auto', sm: 'none' },
                  }}
                  style={{"textAlign":"center"}}
                >
                  <img src={process.env.PUBLIC_URL+''+razd.img} />
                </Box>
                <div>
                  <Typography component="h2" variant="h4" color="text.primary">
                    {razd.zag}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ mb: { xs: 2, sm: 4 } }}
                  >
                    {razd.text.split('\n').map((line) => 
                      <p>
                        {line}
                      </p>
                    )}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    sx={{ mb: { xs: 2, sm: 4 } }}
                  >
                    <b>Важно:</b> {razd.vagno}
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
              >
                <img src={process.env.PUBLIC_URL+''+razd.img} />
              </Grid>
              <Container
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: { xs: 4, sm: 8 },
                    pb: { xs: 2, sm: 6 },
                  }}
                >
                <Button variant="contained" onClick={mailsend} style={{backgroundColor: "#c73080"}}>
                Получить разбор остальных позиций
                </Button>
                  {/*<a href='https://vk.com/im?sel=135493950' variant="contained" color="primary" >
                      Получит разбор остальных позиций
                  </a>*/}
                </Container>
            </Grid>
                )
          }
          
        })
      
    )
    }
    </Container>
  );
}
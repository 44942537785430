import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Link, Stack, TextField } from '@mui/material';
import { alpha } from '@mui/material';
import Car from './Car';


export default function Cart({blok,iPoz,iiPoz,iiiPoz,ivPoz,vPoz,viPoz}) {
/*9CCCFC
*/
  return (
    <Box id="logoCollection" >
    {
      blok!=0 && <>
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        color="text.secondary"
      >
        
      </Typography>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
      <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            textAlign:"center",
            backgroundSize: 'cover',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#BFCCD9', 0.5)
                : alpha('#c73080', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha('#c73080', 0.2)}`
                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
          })}
        ><div style={{padding:"10px"}}>
          <div>1 позиция - Задача души и таланта</div>
          <div>2 позиция - Школа взросления, уроки</div>
          <div>3 позиция - Экзаменатор, итог</div>
          <div>4 позиция - Внутренний конфликт, тень</div>
          <div>5 позиция - Ценности</div>
          <div>6 позиция - Дар</div>
        </div></Box>

        <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <div style={{whiteSpace:"nowrap"}}>
        <Car pos='1' cart={iPoz}/>
        <Car pos='2' cart={iiPoz}/>
        <Car pos='3' cart={iiiPoz}/>
      </div>
      <div>
        <Car pos='4' cart={ivPoz}/>
        <Car pos='5' cart={vPoz}/>
      </div>
      <div>
        <Car pos='6' cart={viPoz}/>
      </div>
    </Container>

        </Container>
      </>
    }
   
    </Box>
  );
}

/*<Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Car pos='1' cart={iPoz}/>
        <Car pos='2' cart={iiPoz}/>
        <Car pos='3' cart={iiiPoz}/>
      </Grid>
      <Grid container justifyContent="center"alignItems="center" spacing={2}>
        <Car pos='4' cart={ivPoz}/>
        <Car pos='5' cart={vPoz}/>
      </Grid>
      <Grid container justifyContent="center"alignItems="center" spacing={2}>
        <Car pos='6' cart={viPoz}/>
      </Grid>*/